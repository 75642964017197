<template>
    <div class="leftTabs">
        <p :id="flagBgc == index ? 'bgc' : ''" v-for="(item, index) in this.tabList" @click="clcikBgc(index, item.url)" :key="index" class="shou">
            {{item.name}}
        </p>
    </div>
</template>
<script>
export default {
    props: {
        tabList: {
           type: Array
        }
    },
    data () {
        return {
           flagBgc: 0,
        };
    },
    methods:{
       clcikBgc(index, url) {
           if(this.flagBgc == index) {
               return;
           }
           this.flagBgc = index
           window.localStorage.setItem('leftindex', index)
           this.$router.push(url)
           console.log('9999999')
       }
    },
    created() {
        if(window.localStorage.getItem('leftindex')) {
            this.flagBgc = window.localStorage.getItem('leftindex')
        }
        console.log('6666666', this.tabList)
    },
    mounted() {
    }
}
</script>
<style scoped lang="less">
    .leftTabs {
        width: 100px;
        background-color: #fff;
        height: 100vh;
        border: 1px solid #eee;
        p {
            height: 50px;
            text-align: center;
            line-height: 50px;
            color: #666666;
            font-size: 14px;
            &:hover {
                background-color: #edf6ff;
            }
        }
    }

#bgc {
    background-color: #edf6ff;
}
</style>