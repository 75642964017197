<template>
  <div class="shop">
    <left-tabs :tabList="leftList" />
    <router-view></router-view>
  </div>
</template>

<script>
import LeftTabs from "@/components/leftTabs";
export default {
  components: { LeftTabs },
  name: "shop",
  data() {
    return {
      leftList: [
        { id: 1, name: "管理员", url: '/jurisdiction/admin' },
        { id: 1, name: "角色", url: '/jurisdiction/role' },
        { id: 1, name: "管理员日志", url: '/jurisdiction/oplog' },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped lang='less'>
.shop {
  display: flex;
}
</style>